import { HStack, Box, Badge, Tooltip } from '@mybridge/ui/layout';
import { IconButton } from '@mybridge/ui/icon-button';
import { TeamNewIcon } from '@mybridge/icons';
import ConversationList from 'v4/components/header/conversation-list';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';
import { Button, Divider } from '@mybridge/ui';
import { EventIconColored } from '@mybridge/icons';
import { setMeetNow } from 'v4/store/actions/meeting.actions';
import { ProfileMenu } from './profile-menu'; // Import ProfileMenu
import MyBridgeAvatar from 'v4/components/common/MyBridgeAvatar';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { TeamsChatIcon } from '@mybridge/icons/TeamsChatIcon';


import { background, VStack } from '@chakra-ui/react';

export const PostHeaderMenu = (props) => {
  const { loggedIn } = useSelector((state) => state.user);
  const { teamChatNotications } = useSelector((state) => state.chatReducer);
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const { push } = useRouter();

  const userFullName = getUserFullName(userProfileInfo);
  const userProfilePic = getUserProfilePic(userProfileInfo);

  return (
    <div style={{ display: 'flex', justifyContent: 'end', width: "100%" }}>
      {loggedIn && (
        <HStack spacing={2} mr={2} style={{ flex: 1, justifyContent: 'end' }}>
          <Box
            position="relative"
            onClick={() => {
              push('/teams');
              currentMeetingState && dispatch(setMeetingWindowed());
            }}
            sx={{
              cursor: 'pointer', // Set cursor to pointer on hover
            }}
          >
            <TeamsChatIcon />
          </Box>


          {/* Teams Button */}
          {/* <IconButton
            position="relative"
            onClick={() => {
              push('/teams');
              currentMeetingState && dispatch(setMeetingWindowed());
            }}
            style={{ backgroundColor: '#5756465e', borderRadius: '50%' }} // Grey background and rounded corners
          >
            {teamChatNotications > 0 && (
              <Badge
                position="absolute"
                bg="brandRed.500"
                color="white"
                borderRadius="100%"
                top="0"
                minW="25px"
                border="2px solid #ffffff"
                p="3px 5px"
                left="50%"
              >
                {teamChatNotications}
              </Badge>
            )}
            <Tooltip label="Teams">
              <Box as="span" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }}>
                <TeamsChatIcon/>
              </Box>
            </Tooltip>
          </IconButton> */}


          {/* Messenger */}
          <Box
          mx={2}
            style={{
              backgroundColor: '#5756465e',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              height: '40px',
            }}
          >
            <ConversationList width="20" height="16.17" onClick />
          

          </Box>

          <Button
            onClick={() => dispatch(setMeetNow(true))}
            variant="ghost"
            height="50px"
            paddingInlineStart={"0px"}
            paddingInlineEnd={"0px"}
            _hover={{
              backgroundColor:"transparent"
               }}
            leftIcon={
              <Box
            
                style={{
                  backgroundColor: '#5756465e',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  height: '40px',
                }}
              >
                <EventIconColored width="20" height="20" />
              </Box>
            }
            justifyContent="flex-start"
            fontWeight="600"
            color='#3D5A80'
          />

          {/* Profile Menu */}
          <ProfileMenu>
            <Button
              borderRadius="100%"
              padding={0}
              variant="ghost"
              style={{ boxShadow: 'rgb(0 0 0 / 43%) 0px 2px 15px 0px' }}
            >
              <Box as="span">
                <MyBridgeAvatar
                  name={userFullName}
                  src={userProfilePic}
                  size="sm"
                  style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'grey' }} // Grey background for avatar
                />
              </Box>
            </Button>
          </ProfileMenu>
        </HStack>
      )}


    </div>
  );
};